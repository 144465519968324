'use client';

import { motion, AnimatePresence } from 'framer-motion';
import Link from 'next/link';
import { BallTriangleLoader } from 'ui';
import { BasicDropDown } from 'ui/client';
import { useLogoutHandler } from '../hooks';
import { useSessionQuery } from '../queries/session';
import { useCurrentUserQuery } from 'src/generated/graphql';
import Image from 'next/image';

export type UserInfoProps = {};

export const UserInfo: React.FC<UserInfoProps> = () => {
  const session = useSessionQuery();
  const currentUser = useCurrentUserQuery(
    {},
    {
      queryKey: ['currentUser', session.data?.identity.id],
      enabled: !!session.data?.identity.id,
    },
  );

  const logoutHandler = useLogoutHandler(!!session.data);

  const logout = (close: () => void) => {
    close();
    logoutHandler();
  };

  return (
    <AnimatePresence mode="wait">
      {(function () {
        if (currentUser.isLoading || session.isLoading) {
          return (
            <motion.div
              animate={{ opacity: 0.5 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.15 }}
              key="loading"
            >
              {<BallTriangleLoader className="w-8" />}
            </motion.div>
          );
        }

        if (session.data && currentUser.data) {
          return (
            <motion.div
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{ ease: 'backOut', duration: 0.2 }}
              key="user"
            >
              <BasicDropDown
                autoClose
                items={[
                  { label: 'Logout', value: 'logout', onClick: (close) => logout(close) },
                ]}
              >
                <div className="flex items-center">
                  <div className="px-4" data-hj-allow>
                    {currentUser.data.me?.name || session.data.identity.traits['email']}
                  </div>
                  {currentUser.data.me?.image_url && (
                    <div className="w-10 h-10 rounded-full overflow-hidden relative">
                      <Image
                        fill
                        sizes="100%"
                        
                        src={currentUser.data.me.image_url}
                        alt={currentUser.data.me.name}
                      />
                    </div>
                  )}
                </div>
              </BasicDropDown>
            </motion.div>
          );
        }

        // User not logged in
        // maybe not necessary since we redirect to login atm anyway?
        // depends on future pages but doesn't hurt either
        return (
          <motion.div
            animate={{ opacity: 1 }}
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            key="login"
          >
            <Link href="/login">Login</Link>
          </motion.div>
        );
      })()}
    </AnimatePresence>
  );
};
