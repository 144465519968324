import { motion, AnimatePresence } from 'framer-motion';
import { useRef, useState } from 'react';
import { useDocumentClick } from './hooks';

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ');
}

export type BasicDropDownProps = {
  children?: React.ReactNode;
  items: {
    label: string;
    value: string;
    onClick?: (close: () => void) => void;
  }[];

  // openOnActive opens the dropdown when clicking the main item, instead of toggling it
  openOnActive?: boolean;
  onClick?: (value: string, close: () => void) => void;
  // autoClose closes the dropdown when the user clicks outside of it
  autoClose?: boolean;
  // fullWidth makes the dropdown take up the full width of the item
  fullWidth?: boolean;
};

export const BasicDropDown: React.FC<BasicDropDownProps> = ({
  children,
  items,
  autoClose = false,
  fullWidth,
  onClick,
  openOnActive,
}) => {
  const [active, setActive] = useState(false);
  const close = () => setActive(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useDocumentClick(() => {
    if (autoClose && active) {
      close();
    }
  }, wrapperRef.current);

  return (
    <motion.div className="relative inline-block text-left" ref={wrapperRef}>
      <div>
        <motion.button
          onClick={() => setActive((a) => openOnActive || !a)}
          className="inline-flex justify-center w-full"
        >
          {children}
        </motion.button>
      </div>

      <AnimatePresence>
        {!!items.length && active && (
          <motion.ul
            initial={{ opacity: 0, scale: 0.95, y: -5 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: -5 }}
            transition={{ duration: 0.17 }}
            className={`
              origin-top-right absolute right-0 mt-2 rounded-md shadow-lg 
              bg-white ring-1 ring-black ring-opacity-5 focus:outline-none
              z-10
              ${fullWidth ? 'w-full' : 'w-56'}
            `}
          >
            <div className="py-1">
              {items.map((item) => (
                <motion.li key={item.value}>
                  <button
                    className={classNames(
                      'text-gray-700',
                      'block px-4 py-2 text-sm w-full text-right',
                    )}
                    onClick={() => {
                      item.onClick?.(close);
                      onClick?.(item.value, close);
                    }}
                  >
                    {item.label}
                  </button>
                </motion.li>
              ))}
            </div>
          </motion.ul>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export type DropDownProps = Pick<BasicDropDownProps, 'items'> & {};

export const DropDown: React.FC<DropDownProps> = ({ items }) => {
  return (
    <BasicDropDown items={items} autoClose fullWidth>
      <div className="bg-white border-2 border-gray-200 text-gray-600 rounded-md w-full transition-colors focus:border-gray-400 outline-none peer-[button] disabled:bg-gray-200">
        <input className="w-full py-1.5 px-2 outline-none bg-transparent" />
      </div>
    </BasicDropDown>
  );
};
