import React, { ReactNode, useRef, useLayoutEffect, memo } from 'react';
import { useState } from 'react';
import { DataTableValue } from './DataTableValue';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/solid';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { Pagination } from './Pagination';
import { motion } from 'framer-motion';
import { Triangle } from './icons/Triangle';

export type DataFrameDict = {
  [key: string]: {
    [index: string]: string;
  };
};

export type PivotedDataFrame = {
  [key: string]: string;
}[];

export function pivotDataFrameDict(dataFrameDict: DataFrameDict): PivotedDataFrame {
  const pivotedDataFrame: PivotedDataFrame = [];
  const keys = Object.keys(dataFrameDict);
  for (const indexStr in dataFrameDict[keys[0]]) {
    const row: PivotedDataFrame[0] = {};
    for (const key of keys) {
      row[key] = dataFrameDict[key][indexStr];
    }
    pivotedDataFrame.push(row);
  }
  return pivotedDataFrame;
}

export interface DataTableColumn {
  key: string;
  label?: string | ReactNode;
  render?: (value: any | undefined, table: DataFrameDict, row: number) => ReactNode | null;
  sort?: (a: string, b: string, desc: boolean) => number;
  trackClick?: boolean;
}

export const DataTable: React.FC<{
  table: DataFrameDict;
  columns: DataTableColumn[];
  groupBy?: string[];
  alignLeft?: boolean; // alignLeft makes all table content to only take up the minimum space possible instead of taking up the whole width of the table
  greyedOutRows?: number[];
  onLinkClick?: (url: string) => void;
}> = ({ table, columns, groupBy, alignLeft, greyedOutRows = [1, 2, 3], onLinkClick }) => {
  const [sortBy, setSortBy] = useState<{ column: string; desc: boolean } | undefined>(
    undefined,
  );
  // const tableRows = useMemo(() => pivotDataFrameDict(table), [table]);
  // const groupedTableRows = useMemo(() => {

  // }, [tableRows])

  const [toggledRows, setToggledRows] = useState<{ [key: number]: boolean }>({});
  const keys = useMemo(() => Object.keys(table), [table]);
  const cols = useMemo(() => columns.length, [columns]);
  const rows = useMemo(() => Object.values(table[keys[0]]).length, [table, keys]);
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10;

  useEffect(() => {
    setCurrentPage(0);
  }, [sortBy]);

  const toggle = (row: number) => {
    setToggledRows((prev) => ({ ...prev, [row]: !prev[row] }));
  };

  const indexes = useMemo(() => Array.from(Array(rows).keys()), [rows]);
  const sortedIndexes = useMemo(() => {
    if (!sortBy) {
      return indexes;
    }

    return [...indexes].sort((a, b) => {
      const aVal = table[sortBy.column][a];
      const bVal = table[sortBy.column][b];

      const sortFn = columns.find((c) => c.key === sortBy.column)?.sort;
      if (sortFn) {
        return sortFn(aVal, bVal, sortBy.desc);
      }

      if (aVal < bVal) {
        return sortBy.desc ? 1 : -1;
      }
      if (aVal > bVal) {
        return sortBy.desc ? -1 : 1;
      }
      return 0;
    });
  }, [columns, indexes, sortBy, table]);

  const groupedIndexes: (number | number[])[] = useMemo(() => {
    if (!groupBy) {
      return sortedIndexes;
    }

    const groupDict: { [key: string]: number[] } = {};
    return sortedIndexes.reduce<(number | number[])[]>((acc, index) => {
      // do not group if any value is undefined or empty
      const values = groupBy.map((key) => table[key][index]);
      const isEmpty = values.some((value) => !value || value === '');
      if (isEmpty) {
        return acc.concat(index);
      }

      const groupKey = groupBy.map((key) => table[key][index]).join('-');
      if (!groupDict[groupKey]) {
        groupDict[groupKey] = [];
        acc.push(groupDict[groupKey]);
      }
      groupDict[groupKey].push(index);
      return acc;
    }, []);
  }, [groupBy, sortedIndexes, table]);

  const pages = Math.ceil(groupedIndexes.length / pageSize);
  const start = currentPage * pageSize;
  const end = start + pageSize;

  const hasGroups = groupedIndexes.some(
    (index) => Array.isArray(index) && index.length > 1,
  );

  const headColumns: DataTableColumn[] = hasGroups
    ? [{ key: 'group', label: ' ' }, ...columns]
    : columns;

  const [minHeight, setMinHeight] = useState<number>(0);
  const [minWidth, setMinWidth] = useState<number>(0);
  const bodyRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    if (!bodyRef.current) {
      return;
    }

    if (bodyRef.current.clientHeight > minHeight) {
      setMinHeight(bodyRef.current.clientHeight);
    }
    if (bodyRef.current.clientWidth > minWidth) {
      setMinWidth(bodyRef.current.clientWidth);
    }
  }, [minHeight, minWidth, currentPage]);

  return (
    <div className="flex flex-col">
      <div
        ref={bodyRef}
        style={{ minHeight: `${minHeight}px` }}
        className="overflow-x-auto whitespace-nowrap mb-2"
      >
        <table className="w-full mb-1">
          <thead>
            <tr className="text-gray-500">
              {headColumns.map(({ key, label }, index) => (
                <th
                  key={key}
                  onClick={() =>
                    setSortBy((prev) => {
                      if (prev && prev.column === key) {
                        return prev.desc ? undefined : { column: key, desc: true };
                      }
                      return { column: key, desc: false };
                    })
                  }
                  className={`font-semibold text-xs p-2 bg-gray-100 select-none cursor-pointer ${
                    index === 0 ? 'rounded-l-md' : ''
                  } ${!alignLeft && index === cols - 1 ? 'rounded-r-md' : ''}`}
                >
                  <span className="flex items-center">
                    {label || key}{' '}
                    {sortBy && sortBy.column === key && (
                      <Triangle className={sortBy.desc ? 'rotate-180' : ''} />
                    )}
                  </span>
                </th>
              ))}
              {!!alignLeft && (
                <th className="w-full font-semibold text-xs p-2 bg-gray-100 rounded-r-md"></th>
              )}
            </tr>
          </thead>
          <motion.tbody className="min-w-full">
            {groupedIndexes.slice(start, end).map((rows, index) => {
              const isGroup = Array.isArray(rows) && rows.length > 1;
              const row = Array.isArray(rows) ? (rows.length ? rows[0] : null) : rows;
              const otherRows = isGroup ? rows.slice(1) : [];

              if (row === null) {
                console.error('no row', rows, index, indexes);
                return;
              }

              const groupActive = isGroup && toggledRows[row];
              const greyedOut = greyedOutRows.includes(row);

              return (
                <React.Fragment key={row}>
                  <tr key={row} className={`${greyedOut ? 'text-gray-400/80' : ''}`}>
                    {isGroup ? (
                      <td onClick={() => toggle(row)}>
                        <ChevronDoubleRightIcon
                          className={`transition-transform ${
                            groupActive ? 'rotate-90' : ''
                          }`}
                        />
                      </td>
                    ) : hasGroups ? (
                      <td></td>
                    ) : null}

                    <MemoDataTableRowColumns
                      columns={columns}
                      row={row}
                      table={table}
                      onLinkClick={onLinkClick}
                    />
                  </tr>
                  {groupActive &&
                    otherRows.map((row) => (
                      <tr
                        key={row}
                        className={`shadow-inner bg-gray-100/50 ${
                          greyedOutRows.includes(row) ? 'text-gray-400/80' : ''
                        }`}
                      >
                        {<td></td>}
                        <DataTableRowColumns
                          columns={columns}
                          row={row}
                          table={table}
                          onLinkClick={onLinkClick}
                        />
                      </tr>
                    ))}
                </React.Fragment>
              );
            })}
          </motion.tbody>
        </table>
      </div>
      {pages > 1 && (
        <div>
          <Pagination
            current={currentPage}
            pages={pages}
            onChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
    </div>
  );
};

function DataTableRowColumns({
  table,
  columns,
  row,
  className,
  onLinkClick,
}: {
  table: DataFrameDict;
  columns: DataTableColumn[];
  row: number;
  className?: string;
  onLinkClick?: (url: string) => void;
}): JSX.Element {
  return (
    <>
      {columns.map(({ key, render, trackClick }) => (
        <td key={key} className={`py-1 px-2 text-xs ${className || ''}`}>
          {render ? (
            render(table[key][row], table, row)
          ) : (
            <DataTableValue onLinkClick={trackClick ? onLinkClick : undefined}>
              {table[key]?.[row]}
            </DataTableValue>
          )}
        </td>
      ))}
    </>
  );
}

const MemoDataTableRowColumns = memo(DataTableRowColumns);
