export function hashCode(value: string) {
  let hash = 0;
  let chr = 0;
  if (value.length === 0) return hash;
  for (let i = 0; i < value.length; i++) {
    chr = value.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
}

export function generateTempID(): string {
  return `temp_id_${Math.floor(Math.random() * 100000000)}`;
}

export function naturalDate(date: Date, options?: { dropMidnight?: boolean }): string {
  const currentDate = new Date();
  const dropMidnight = options?.dropMidnight ?? false;

  const diffInMilliseconds = currentDate.getTime() - date.getTime();
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInDays = Math.floor(diffInMilliseconds / oneDay);

  let datePhrase = '';

  if (diffInDays === 0) {
    datePhrase = 'Today';
  } else if (diffInDays === 1) {
    datePhrase = 'Yesterday';
  } else if (diffInDays < 30) {
    datePhrase = `${diffInDays} days ago`;
  } else if (diffInDays < 365) {
    const diffInMonths = Math.floor(diffInDays / 30);
    datePhrase = diffInMonths === 1 ? '1 month ago' : `${diffInMonths} months ago`;
  } else {
    const diffInYears = Math.floor(diffInDays / 365);
    datePhrase = diffInYears === 1 ? '1 year ago' : `${diffInYears} years ago`;
  }

  // Construct the time part
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // am/pm:
  // const timeSuffix = hours >= 12 ? 'pm' : 'am';
  // const displayHours = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;

  // 24 hour:
  const timeSuffix = '';
  const displayHours = hours;

  if (dropMidnight) {
    if (hours === 0 && minutes === 0) {
      return datePhrase;
    }
  }

  const formattedTime = `${displayHours}:${minutes.toString().padStart(2, '0')} ${timeSuffix}`;

  return `${datePhrase} at ${formattedTime}`;
}

type ReadableDateOptions = {
  dropMidnight?: boolean; // Drop midnight times
  withinPeriod?: number; // Number of days within which to use natural date format
  short?: boolean; // Use short date format
  hideTime?: boolean; // Exclude time
};

export function readableDate(input: Date | string, options?: ReadableDateOptions): string {
  const userLocale = navigator.language || 'en-US'; // Fallback to 'en-US' if navigator.language is not available
  const inputDate = typeof input === 'string' ? new Date(input) : input;
  const currentDate = new Date();
  const diffInMilliseconds = currentDate.getTime() - inputDate.getTime();
  const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));

  if (options?.withinPeriod !== undefined && diffInDays <= options.withinPeriod) {
    return naturalDate(inputDate, options);
  }

  const dateOptions: Intl.DateTimeFormatOptions = {
    // weekday: '',
    year: 'numeric',
    month: options?.short ? 'numeric' : 'long',
    day: 'numeric',
    hour12: false,
  };

  if (options?.dropMidnight && !options.hideTime) {
    if (inputDate.getHours() !== 0 || inputDate.getMinutes() !== 0) {
      dateOptions.hour = "numeric";
      dateOptions.minute = "2-digit";
    }
  }

  return inputDate.toLocaleDateString(userLocale, dateOptions);
}
