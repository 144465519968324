import { AxiosError } from 'axios';
import { Session, Identity } from '@ory/kratos-client';
import { useQuery, UseQueryResult } from 'react-query';
import ory from '../ory';
import { useMemo } from 'react';

const getSession = () => ory.toSession().then((res) => res.data);

export const useSessionQuery = (): UseQueryResult<Session | undefined, AxiosError> => {
  return useQuery<Session | undefined, AxiosError>(
    ['ory', 'session'],
    () =>
      getSession().catch((error: AxiosError) => {
        if (error.response?.status === 401) {
          return undefined;
        }

        throw error;
      }),
    {
      retry: false,
      refetchOnReconnect: true,
    },
  );
};

export const useIdentity = (): Identity | undefined => {
  const { data } = useSessionQuery();
  return data?.identity;
};

export const useIdentityInfo = (): {
  id: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
} => {
  const identity = useIdentity();
  const info = useMemo(
    () => ({
      id: identity?.id,
      firstName: identity?.traits.firstName,
      lastName: identity?.traits.lastName,
      email: identity?.traits.email,
    }),
    [
      identity?.id,
      identity?.traits.firstName,
      identity?.traits.lastName,
      identity?.traits.email,
    ],
  );
  return info;
};

export const useIdentityEmail = () => {
  const { email } = useIdentityInfo();
  return email;
};
