import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ReactElement, ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppLayout } from '../lib/layouts/AppLayout';
import '../styles/globals.scss';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../src/filter/select.css';
import { Analytics } from 'src/analytics';
import environment from 'src/environment';

const queryClient = new QueryClient();

// App default layout
const getDefaultLayout = (page: ReactNode) => <AppLayout>{page}</AppLayout>;

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const env = environment.ENV
const isProd = environment.IS_PRODUCTION;
const deployed_branch = environment.DEPLOYED_BRANCH;
const enableAnalytics = environment.IS_PRODUCTION;

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || getDefaultLayout;

  let title = 'March Companion';
  if (!isProd) {
    title += ' - ' + (deployed_branch || env || 'dev');
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <title>{title}</title>
        <link rel="icon" type="image/png" href="/favicon-web-32x32.png" sizes="32x32" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon-180x180.png"
        />
      </Head>
      {enableAnalytics && <Analytics />}
      {getLayout(<Component {...pageProps} />)}
    </QueryClientProvider>
  );
}
