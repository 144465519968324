import { IconProps } from './IconProps';

export const CrossIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    width="24"
    height="auto"
    className={className}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5849 12L6.34225 7.75736L7.75646 6.34315L11.9991 10.5858L16.2417 6.34315L17.656 7.75736L13.4133 12L17.656 16.2426L16.2417 17.6569L11.9991 13.4142L7.75646 17.6569L6.34225 16.2426L10.5849 12Z"
      fill="currentColor"
    />
  </svg>
);
