import { IconProps } from './IconProps';

export const Triangle: React.FC<IconProps> = ({ className }) => (
  <svg
    width="15"
    className={className}
    viewBox="0 0 15 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.50025 0.500978L14.5002 9.50098L0.500248 9.50098L7.50025 0.500978Z"
      fill="currentColor"
    />
  </svg>
);
