import { useEffect, useState } from 'react';

export const useMin = (value: number) => {
  const [min, setMin] = useState(value);
  useEffect(() => {
    if (min < value) {
      setMin(value);
    }
  }, [value, min]);
  return [min, setMin];
};

export const useMax = (value: number): [max: number, reset: (value: number) => void] => {
  const [max, setMax] = useState(value);
  useEffect(() => {
    if (value > max) {
      setMax(value);
    }
  }, [max, value]);
  return [max, setMax];
};
