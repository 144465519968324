import { motion, LayoutGroup } from 'framer-motion';
import { useEffect } from 'react';

export type TabsMenuProps<T extends number | string> = {
  items: {
    label: string;
    value: T;
  }[];
  value?: T;
  onChange?: (value: T) => void;
  // defaultToFirst sets the first tab as the default selected tab
  // this triggers when value is undefined and when there are tabs to select
  defaultToFirst?: boolean;
  disabled?: boolean;
};

const spring = {
  type: 'spring',
  stiffness: 500,
  damping: 30,
};

export function SlidingTabsMenu<T extends number | string>({
  items,
  onChange,
  value,
  disabled,
  defaultToFirst,
}: TabsMenuProps<T>) {
  useEffect(() => {
    // existing useEffect code...
  }, [defaultToFirst, items, onChange, value]);

  return (
    <nav
      className="flex w-full border-2 border-slate-200 rounded overflow-x-hidden"
      title="tabs navigation"
    >
      <LayoutGroup>
        {items.map((item) => (
          <button
            disabled={disabled}
            className={`flex-grow flex-shrink min-w-0 py-1.5 text-center relative transition-colors disabled:brightness-50
              ${item.value === value ? 'text-white' : ''}
            `}
            key={item.value}
            onClick={() => onChange?.(item.value)}
          >
            <span className="relative w-full z-10 px-5 text-base">{item.label}</span>
            {item.value === value && (
              <motion.div
                // layout="position"
                // layoutId="tabs-menu-active-tab"
                className="absolute w-full h-full bg-secondary-brand top-0 left-0"
                initial={false}
                animate={{}}
                transition={spring}
              />
            )}
          </button>
        ))}
      </LayoutGroup>
    </nav>
  );
}
