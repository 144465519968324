import React, { ForwardedRef, forwardRef } from 'react';

export type TextInputProps = {
  id?: string;
  name?: string;
  placeholder?: string;
  state?: 'idle' | 'error' | 'success' | 'loading';
  type?: 'text' | 'password' | 'number' | 'email' | 'tel' | 'url';
  value?: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  className?: string;
  autoFocus?: boolean;
};

const GreyTextInputWithRef = (
  { className = '', ...props }: TextInputProps,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element => {
  return (
    <input
      ref={ref}
      className={`bg-gray-200 w-full py-4 px-3 ${className} disabled:bg-300 outline-none focus:shadow-inner`}
      {...props}
    />
  );
};

export const GreyTextInput = forwardRef<HTMLInputElement, TextInputProps>(
  GreyTextInputWithRef,
);

const TextInputWithRef = (
  { state = 'idle', className = '', disabled, ...props }: TextInputProps,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element => {
  const inputDisabled = disabled || state === 'loading';
  return (
    <input
      ref={ref}
      disabled={inputDisabled}
      className={`
        border
        ${state === 'idle' ? 'bg-white border-slate-200 text-gray-600' : ''}
        ${state === 'success' ? 'bg-white border-green-300 text-gray-600' : ''}
        ${state === 'error' ? 'bg-white border-red-300 text-gray-600' : ''}
        rounded-md w-full py-1.5 px-2 transition-colors
        outline-none peer-[button]
        focus:border-gray-400
        disabled:bg-gray-200
        ${className}
      `}
      {...props}
    />
  );
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(TextInputWithRef);

const SmallTextInputWithRef = (
  { state = 'idle', className = '', disabled, ...props }: TextInputProps,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element => {
  const inputDisabled = disabled || state === 'loading';
  return (
    <input
      ref={ref}
      disabled={inputDisabled}
      className={`
        border
        ${state === 'idle' ? 'bg-white border-slate-200 text-gray-600' : ''}
        ${state === 'success' ? 'bg-white border-green-300 text-gray-600' : ''}
        ${state === 'error' ? 'bg-white border-red-300 text-gray-600' : ''}
        rounded-md w-full py-1 px-2 transition-colors 
        outline-none peer-[button] 
        focus:border-gray-400 
        disabled:bg-gray-200
        ${className}
      `}
      {...props}
    />
  );
};

export const SmallTextInput = forwardRef<HTMLInputElement, TextInputProps>(
  SmallTextInputWithRef,
);

type TextAreaProps = {
  id?: string;
  name?: string;
  cols?: number;
  rows?: number;
  placeholder?: string;
  type?: 'text' | 'password' | 'number' | 'email' | 'tel' | 'url';
  value?: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  className?: string;
  autoFocus?: boolean;
};

const SmallTextAreaWithRef = (
  { className = '', ...props }: TextAreaProps,
  ref: ForwardedRef<HTMLTextAreaElement>,
): JSX.Element => {
  return (
    <textarea
      ref={ref}
      className={`bg-white border border-slate-200 text-gray-600 rounded-md w-full py-1 px-2 transition-colors focus:border-gray-400 outline-none peer-[button] disabled:bg-gray-200 ${className}`}
      {...props}
    />
  );
};

export const SmallTextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  SmallTextAreaWithRef,
);

type SimpleSelectProps = {
  options: (string | { label: string; value: string })[];
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  className?: string;
  showEmpty?: boolean;
  placeholder?: string;
  disabled?: boolean;
};

export const SimpleSelect: React.FC<SimpleSelectProps> = ({
  options,
  value,
  onChange,
  className = '',
  showEmpty = false,
  placeholder = 'select an option',
  disabled,
}) => {
  // if value is not in options, set value to undefined
  if (
    value &&
    !options.find((option) =>
      typeof option === 'string' ? option === value : option.value === value,
    )
  ) {
    value = undefined;
  }

  return (
    <select
      disabled={disabled}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={`
      bg-white border border-slate-200 text-gray-600 rounded-md py-1 transition-colors focus:border-gray-400 outline-none peer-[button] disabled:bg-gray-200
      ${className}`}
      placeholder={placeholder}
    >
      {showEmpty && !value && <option value={undefined}></option>}
      {options.map((option) => {
        if (typeof option === 'string') {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          );
        } else {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        }
      })}
    </select>
  );
};

export default SimpleSelect;
