import { useIdentityInfo } from 'lib/queries/session';
import { memo, useEffect } from 'react';
import { hotjar } from 'react-hotjar';

const hotjarSiteId =
  process.env.NEXT_PUBLIC_HOTJAR_SITE_ID &&
  parseInt(process.env.NEXT_PUBLIC_HOTJAR_SITE_ID);

export const useAnalytics = () => {
  useEffect(() => {
    if (hotjarSiteId) {
      hotjar.initialize(hotjarSiteId, 6);
    }
  }, []);

  const identityInfo = useIdentityInfo();

  useEffect(() => {
    if (identityInfo.id && hotjar.initialized()) {
      hotjar.identify(identityInfo.id, { email: identityInfo.email });
    }
  }, [identityInfo]);
};

const AnalyticsComponent: React.FC = () => {
  useAnalytics();
  return null;
};

export const Analytics = memo(AnalyticsComponent);
