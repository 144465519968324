import Link from 'next/link';
import { ReactNode } from 'react';

import { HamburgerLogo } from './logos';

export type HeaderBrightProps = {
  children?: ReactNode;
  className?: string;
  logo?: ReactNode;
  onToggleMenu?: () => void;
  end?: ReactNode;
};

export const HeaderBright: React.FC<HeaderBrightProps> = ({
  className,
  logo,
  children,
  onToggleMenu,
  end,
}) => {
  return (
    <header
      className={`bg-white border-b-gray-300 border-b py-5 px-4 flex flex-col sm:grid grid-cols-3 xl:grid-cols-5 items-center gap-6 sm:h-20 ${className}`}
    >
      <div className="flex flex-row">
        {onToggleMenu && (
          <button
            onClick={onToggleMenu}
            className="active:opacity-75 hover:scale-105 active:scale-95 transition-all"
          >
            <HamburgerLogo className="w-12 cursor-pointer text-black" />
          </button>
        )}
        <Link className="cursor-pointer" href="/">
          {logo}
        </Link>
      </div>
      <div className="col-start-2 col-end-3 xl:col-end-5 font-medium text-xl">{children}</div>
      <div className="flex sm:justify-end">{end}</div>
    </header>
  );
};
