export const GridLoader: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="105"
    height="105"
    viewBox="0 0 105 105"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    className={className}
  >
    <circle cx="12.5" cy="12.5" r="12.5">
      <animate
        attributeName="fill-opacity"
        begin="0s"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="12.5" cy="52.5" r="12.5" fillOpacity=".5">
      <animate
        attributeName="fill-opacity"
        begin="100ms"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="52.5" cy="12.5" r="12.5">
      <animate
        attributeName="fill-opacity"
        begin="300ms"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="52.5" cy="52.5" r="12.5">
      <animate
        attributeName="fill-opacity"
        begin="600ms"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="92.5" cy="12.5" r="12.5">
      <animate
        attributeName="fill-opacity"
        begin="800ms"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="92.5" cy="52.5" r="12.5">
      <animate
        attributeName="fill-opacity"
        begin="400ms"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="12.5" cy="92.5" r="12.5">
      <animate
        attributeName="fill-opacity"
        begin="700ms"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="52.5" cy="92.5" r="12.5">
      <animate
        attributeName="fill-opacity"
        begin="500ms"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="92.5" cy="92.5" r="12.5">
      <animate
        attributeName="fill-opacity"
        begin="200ms"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

export const BallTriangleLoader: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    width="57"
    viewBox="0 0 57 57"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    className={className}
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="2">
        <circle cx="5" cy="50" r="5">
          <animate
            attributeName="cy"
            begin="0s"
            dur="2.2s"
            values="50;5;50;50"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="cx"
            begin="0s"
            dur="2.2s"
            values="5;27;49;5"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="27" cy="5" r="5">
          <animate
            attributeName="cy"
            begin="0s"
            dur="2.2s"
            from="5"
            to="5"
            values="5;50;50;5"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="cx"
            begin="0s"
            dur="2.2s"
            from="27"
            to="27"
            values="27;49;5;27"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
        <circle cx="49" cy="50" r="5">
          <animate
            attributeName="cy"
            begin="0s"
            dur="2.2s"
            values="50;50;5;50"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="cx"
            from="49"
            to="49"
            begin="0s"
            dur="2.2s"
            values="49;5;27;49"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </g>
  </svg>
);

export const SpinnerLoader: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    style={{ shapeRendering: 'auto' }}
    width="100%"
    className={className}
    viewBox="10 10 80 80"
    preserveAspectRatio="xMidYMid"
  >
    <g transform="rotate(0 50 50)">
      <rect x="48" y="25" rx="1.82" ry="1.82" width="4" height="14" fill="currentColor">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.9900990099009901s"
          begin="-0.9075907590759075s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(30 50 50)">
      <rect x="48" y="25" rx="1.82" ry="1.82" width="4" height="14" fill="currentColor">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.9900990099009901s"
          begin="-0.825082508250825s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(60 50 50)">
      <rect x="48" y="25" rx="1.82" ry="1.82" width="4" height="14" fill="currentColor">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.9900990099009901s"
          begin="-0.7425742574257426s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(90 50 50)">
      <rect x="48" y="25" rx="1.82" ry="1.82" width="4" height="14" fill="currentColor">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.9900990099009901s"
          begin="-0.6600660066006601s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(120 50 50)">
      <rect x="48" y="25" rx="1.82" ry="1.82" width="4" height="14" fill="currentColor">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.9900990099009901s"
          begin="-0.5775577557755776s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(150 50 50)">
      <rect x="48" y="25" rx="1.82" ry="1.82" width="4" height="14" fill="currentColor">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.9900990099009901s"
          begin="-0.495049504950495s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(180 50 50)">
      <rect x="48" y="25" rx="1.82" ry="1.82" width="4" height="14" fill="currentColor">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.9900990099009901s"
          begin="-0.4125412541254125s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(210 50 50)">
      <rect x="48" y="25" rx="1.82" ry="1.82" width="4" height="14" fill="currentColor">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.9900990099009901s"
          begin="-0.33003300330033003s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(240 50 50)">
      <rect x="48" y="25" rx="1.82" ry="1.82" width="4" height="14" fill="currentColor">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.9900990099009901s"
          begin="-0.2475247524752475s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(270 50 50)">
      <rect x="48" y="25" rx="1.82" ry="1.82" width="4" height="14" fill="currentColor">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.9900990099009901s"
          begin="-0.16501650165016502s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(300 50 50)">
      <rect x="48" y="25" rx="1.82" ry="1.82" width="4" height="14" fill="currentColor">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.9900990099009901s"
          begin="-0.08250825082508251s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
    <g transform="rotate(330 50 50)">
      <rect x="48" y="25" rx="1.82" ry="1.82" width="4" height="14" fill="currentColor">
        <animate
          attributeName="opacity"
          values="1;0"
          keyTimes="0;1"
          dur="0.9900990099009901s"
          begin="0s"
          repeatCount="indefinite"
        ></animate>
      </rect>
    </g>
  </svg>
);
