import { IconProps } from './IconProps';

export const StackedSquaresIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    viewBox="0 1 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4686 16.7648C12.017 17.2041 12.7967 17.2038 13.3448 16.7642L20.0167 11.4131L20.2457 11.2303C20.9974 10.6307 20.9987 9.48849 20.2482 8.88719L13.3449 3.35579C12.7967 2.91656 12.0172 2.91656 11.469 3.35579L4.5612 8.89078C3.8122 9.49092 3.81172 10.6304 4.5602 11.2311L4.78687 11.4131L11.4686 16.7648Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3347 19.4705C12.7866 19.9096 12.0072 19.9097 11.459 19.4706L5.52621 14.7187C5.11478 14.3892 4.51294 14.4618 4.19168 14.8797C3.87519 15.2915 3.95204 15.8818 4.36341 16.1988L11.4902 21.6905C12.0297 22.1062 12.7815 22.1063 13.3211 21.6906L20.4445 16.2036C20.8594 15.8841 20.9367 15.2887 20.6172 14.8738C20.2932 14.4532 19.6872 14.3804 19.2729 14.7124L13.3347 19.4705Z"
      fill="currentColor"
    />
  </svg>
);
