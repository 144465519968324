import { IconProps } from './IconProps';

export const RoundMirrorIcon: React.FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.94615 8.10545C3.39801 8.17301 3.00842 8.67212 3.07597 9.22026C3.58728 13.369 6.89885 16.5924 11.0004 17.0481V17.9999H10.0004C8.89582 17.9999 8.00039 18.8953 8.00039 19.9999H16.0004C16.0004 18.8953 15.105 17.9999 14.0004 17.9999H13.0004V17.0483C17.1476 16.5885 20.4828 13.2999 20.9411 9.09425C21.001 8.54522 20.6044 8.05164 20.0554 7.99181C19.5063 7.93198 19.0127 8.32856 18.9529 8.8776C18.5686 12.4042 15.5781 15.1034 12.0012 15.1034C8.46091 15.1034 5.49009 12.4577 5.06095 8.97563C4.9934 8.42749 4.49428 8.0379 3.94615 8.10545Z"
      fill="currentColor"
    />
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 8C18 11.3137 15.3137 14 12 14C8.68629 14 6 11.3137 6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8ZM8.11556 7.1919C8.47711 7.16662 8.7907 7.43922 8.81599 7.80078C8.90331 9.0495 9.45602 9.98539 10.5074 10.6648C10.8118 10.8615 10.7024 11.5721 10.7024 11.5721C10.7024 11.5721 10.0995 11.9638 9.79505 11.7671C8.3967 10.8636 7.62282 9.55317 7.50668 7.89233C7.4814 7.53078 7.754 7.21719 8.11556 7.1919Z"
      fill="currentColor"
    />
  </svg>
);
