import { useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import Link, { LinkProps } from 'next/link';
import React from 'react';

export const ActiveLink: React.FC<
  LinkProps & {
    className?: string;
    activeClassName?: string;
    children?: React.ReactNode;
  }
> = ({ children, className: childClassName, activeClassName, ...props }) => {
  const pathname = usePathname();
  const [className, setClassName] = useState(childClassName);

  useEffect(() => {
    // Check if the router fields are updated client-side
    // Dynamic route will be matched via props.as
    // Static route will be matched via props.href

    const asPathname =
      props.as &&
      (typeof props.as === 'string'
        ? new URL(props.as, location.href).pathname
        : props.as?.pathname);
    const hrefPathname =
      typeof props.href === 'string'
        ? new URL(props.href, location.href).pathname
        : props.href.pathname;

    const linkPathname = asPathname || hrefPathname;

    const newClassName =
      linkPathname === pathname
        ? `${childClassName} ${activeClassName}`.trim()
        : childClassName;

    if (newClassName !== className) {
      setClassName(newClassName);
    }
  }, [
    pathname,
    props.as,
    props.href,
    childClassName,
    activeClassName,
    setClassName,
    className,
  ]);

  return (
    <Link {...props} className={className}>
      {children}
    </Link>
  );
};
