import * as React from 'react';
import { ButtonHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

export const Button = () => {
  return (
    <button className="py-1 px-2 rounded-md text-green-700 bg-green-300 shadow-lg shadow-green-600/50">
      Boop
    </button>
  );
};

export const ActionButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  className = '',
  ...props
}) => (
  <button
    {...props}
    className={`bg-primary-brand text-white flex items-center justify-center font-semibold py-2 px-5 rounded text-base ${className}`}
  >
    {children}
  </button>
);

export const PrimaryButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  className = '',
  ...props
}) => (
  <button
    {...props}
    className={`
      bg-secondary-brand text-white flex items-center justify-center font-semibold py-2 px-5 rounded text-base ${className}
      disabled:bg-slate-200 disabled:text-gray-400
    `}
  >
    {children}
  </button>
);

export const SecondaryButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  className = '',
  ...props
}) => (
  <button
    {...props}
    className={`
      bg-slate-200 text-secondary-brand flex items-center justify-center font-semibold py-2 px-5 rounded text-base ${className}
      disabled:bg-slate-200 disabled:text-gray-400
    `}
  >
    {children}
  </button>
);

export const SmallLinkButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  className = '',
  ...props
}) => (
  <button
    {...props}
    className={`
      text-slate-800 flex items-center justify-center rounded text-sm ${className}
      disabled:text-slate-800 disabled:opacity-50
      hover:text-slate-500 active:text-slate-900
    `}
  >
    {children}
  </button>
);

export const SmallPrimaryLinkButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...props
}) => (
  <button
    {...props}
    className={`
      text-secondary-brand flex items-center justify-center rounded text-sm
      disabled:text-slate-800 disabled:opacity-50
      hover:brightness-90 active:brightness-75
    `}
  >
    {children}
  </button>
);

export const LinkButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  className = '',
  ...props
}) => (
  <button
    {...props}
    className={`
      text-slate-800 flex items-center justify-center font-semibold rounded text-base ${className}
      disabled:text-slate-800 disabled:opacity-50
      hover:text-slate-500 active:text-slate-900
    `}
  >
    {children}
  </button>
);

export const LargePrimaryButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  className = '',
  ...props
}) => (
  <button
    {...props}
    className={twMerge(
      `bg-secondary-brand text-white flex items-center justify-center w-full font-bold py-4 px-4 text-sm`,
      className,
    )}
  >
    {children}
  </button>
);

export const LargeSecondaryButton: React.FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  className = '',
  ...props
}) => (
  <button
    {...props}
    className={`bg-gray-100 text-gray-400 flex items-center justify-center w-full font-bold py-4 px-4 text-sm ${className}`}
  >
    {children}
  </button>
);
