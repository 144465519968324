import { CheckIcon } from '@heroicons/react/24/outline';
import React from 'react';

type LargeCheckboxProps = {
  id?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
};

export const LargeCheckbox: React.FC<LargeCheckboxProps> = ({
  className = '',
  disabled,
  ...inputProps
}) => {
  return (
    <div className={`relative text-white inline-flex justify-center ${className}`}>
      <input
        {...inputProps}
        disabled={disabled}
        type="checkbox"
        className="peer 
        appearance-none bg-white bg-check h-6 w-6
        border-emerald-400 border-2 rounded-sm 
        checked:bg-emerald-400 checked:border-transparent 
        focus:outline-green-300 cursor-pointer hover:scale-105 active:scale-95 transition-all
        disabled:border-gray-200 disabled:cursor-not-allowed"
      />
      <CheckIcon
        className="h-5 w-5 top-0.5 left-0.5
        pointer-events-none
        peer-active:scale-95
        transition-all
        peer-checked:opacity-100 opacity-0 
        absolute z-10"
      />
    </div>
  );
};

export default LargeCheckbox;
