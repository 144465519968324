export const ImmowebLogo = ({ className = '' }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48.000000 48.000000"
    className={className}
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M157 303 l-78 -58 3 -95 3 -95 150 -3 c179 -3 175 -5 175 108 l0 76
-84 62 c-46 34 -85 62 -88 62 -2 0 -39 -26 -81 -57z m141 -40 l52 -38 -2 -65
-3 -65 -37 -3 -38 -3 0 50 c0 50 -1 51 -30 51 -29 0 -30 -1 -30 -51 l0 -50
-37 3 -38 3 -3 65 -3 65 48 37 c26 20 53 37 58 37 6 1 34 -16 63 -36z"
      />
    </g>
  </svg>
);
