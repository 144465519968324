import { GraphQLFormattedError } from 'graphql/error'

export function fetcher<TData, TVariables>(query: string, variables?: TVariables, options?: RequestInit['headers']) {
  return async (): Promise<TData> => {
    const res = await fetch('/api/graphql/query' as string, {
      method: 'POST',
      ...{
        headers: {
          ...options,
          'Content-Type': 'application/json',
        },
      },
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      throw json;
    }

    return json.data;
  };
}

export interface GraphQLErrorResponse {
  errors: GraphQLFormattedError[]
} 
