import { MapIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import { ReactNode, useState } from 'react';
import Confetti from 'react-confetti-explosion';
import environment from 'src/environment';
import MarchLogo from 'src/logos/MarchLogo';
import { Header, StackedSquaresIcon, FourBlocksIcon, RoundMirrorIcon, HeaderBright } from 'ui';
import { SideNav, SideNavLink, SideNavSection } from 'ui/client';

import MarchBaselineLogoWhite from '../../assets/March-Baseline-Logo-White.svg';
import MarchBaselineLogo from '../../assets/March-Baseline-Logo.svg';
import { UserInfo } from '../ui/UserInfo';
// import { MapIcon } from '@heroicons/react/24/outline';

// show omat only on non production environments
// const SHOW_OMAT_LINK = environment.IS_DEVELOPMENT;
const SHOW_OMAT_LINK = true;
const isDevelopment = environment.IS_DEVELOPMENT && !environment.IS_LOCALHOST;
const deployedBranch = environment.DEPLOYED_BRANCH;

function showConfetti() {
  // if date is aug 30 2023
  if (
    new Date().getTime() > new Date(2023, 7, 30).getTime() &&
    new Date().getTime() < new Date(2023, 8, 1).getTime()
  ) {
    return true;
  }
}

export const AppLayout: React.FC<{ children?: ReactNode }> = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [open, setOpen] = useState(true);

  return (
    <div className="h-screen flex flex-col">
      <HeaderBright
        className="grow-0 shrink-0"
        onToggleMenu={() => setOpen((open) => !open)}
        logo={
          <div className="flex flex-row">
            <MarchLogo className={'w-40 text-black'} />
          </div>
        }
        end={<UserInfo />}
      >
        {isDevelopment && (
          <div className="text-rose-400 text-sm">
            <div>
              U bevindt zich nu in de ontwikkelomgeving (
              <span className="uppercase">{deployedBranch || 'DEV'}</span>). Pas op met uw acties.
            </div>
            <a href="https://companion.letsmarch.be" className="hover:underline">
              Klik hier om naar de productieomgeving te gaan.
            </a>
          </div>
        )}
      </HeaderBright>
      <div className="flex flex-row overflow-hidden h-full">
        <SideNav open={open} className="h-full">
          <SideNavSection title="CRM">
            {/* <SideNavLink to="/" icon={<StackedSquaresIcon className="w-full" />}>
              Dashboard
            </SideNavLink> */}
            <SideNavLink to="/crm" icon={<StackedSquaresIcon className="w-full text-gray-800" />}>
              Companion App
            </SideNavLink>
          </SideNavSection>
          <SideNavSection title="MATCHING">
            {true && (
              <SideNavLink
                to="/proactive/filter"
                icon={<FourBlocksIcon className="w-full text-gray-800" />}
              >
                Offer → Demand
              </SideNavLink>
            )}
            <SideNavLink to="/scraper" icon={<RoundMirrorIcon className="w-full text-gray-800" />}>
              Demand → Offer
            </SideNavLink>
            {SHOW_OMAT_LINK && (
              <SideNavLink to="/omat" icon={<MapIcon className="w-full text-gray-800" />}>
                OMAT{showConfetti() && <Confetti />}
              </SideNavLink>
            )}
          </SideNavSection>
          {false && (
            <SideNavSection title="ADMIN">
              <SideNavLink
                to="/admin/users"
                icon={<FourBlocksIcon className="w-full text-gray-800" />}
              >
                Users & Roles
              </SideNavLink>
            </SideNavSection>
          )}
        </SideNav>
        <div className="flex-1 overflow-y-auto">{children}</div>
      </div>
    </div>
  );
};
