import { useCallback, useState, useEffect, RefObject } from 'react';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

interface Size {
  width: number;
  height: number;
  scrollWidth: number;
  scrollHeight: number;
}

export function useElementRefSize<T extends HTMLElement = HTMLDivElement>(
  ref: RefObject<T>,
): Size {
  const [size, setSize] = useState<Size>({
    width: ref.current?.offsetWidth || 0,
    height: ref.current?.offsetHeight || 0,
    scrollWidth: ref.current?.scrollWidth || 0,
    scrollHeight: ref.current?.scrollHeight || 0,
  });

  const handleSize = useCallback(() => {
    setSize({
      width: ref.current?.offsetWidth || 0,
      height: ref.current?.offsetHeight || 0,
      scrollWidth: ref.current?.scrollWidth || 0,
      scrollHeight: ref.current?.scrollHeight || 0,
    });
  }, [ref]);

  useEffect(() => {
    if (ref.current === null) {
      return;
    }

    const observer = new ResizeObserver(handleSize);
    observer.observe(ref.current);

    () => observer.disconnect();
  }, [ref, handleSize]);

  useIsomorphicLayoutEffect(() => {
    handleSize();
  }, [
    ref.current?.offsetHeight,
    ref.current?.offsetWidth,
    ref.current?.scrollHeight,
    ref.current?.scrollWidth,
  ]);

  return size;
}

export function useElementSize<T extends HTMLElement = HTMLDivElement>(): [
  (node: T | null) => void,
  Size,
] {
  const [ref, setRef] = useState<T | null>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
    scrollWidth: 0,
    scrollHeight: 0,
  });

  const handleSize = useCallback(() => {
    setSize({
      width: ref?.offsetWidth || 0,
      height: ref?.offsetHeight || 0,
      scrollWidth: ref?.scrollWidth || 0,
      scrollHeight: ref?.scrollHeight || 0,
    });
  }, [ref?.offsetHeight, ref?.offsetWidth, ref?.scrollHeight, ref?.scrollWidth]);

  useEffect(() => {
    if (ref === null) {
      return;
    }

    const observer = new ResizeObserver(handleSize);
    observer.observe(ref);

    () => observer.disconnect();
  }, [ref, handleSize]);

  useIsomorphicLayoutEffect(() => {
    handleSize();
  }, [ref?.offsetHeight, ref?.offsetWidth]);

  return [setRef, size];
}

export default useElementSize;
