import { motion, Variants } from 'framer-motion';
import { createContext, ReactElement, ReactNode, useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { ActiveLink } from './next';
export type SideNavProps = {
  children?: React.ReactNode;
  className?: string;
  open?: boolean;
};

const wrapperVariants: Variants = {
  closed: { width: 0 },
  small: { width: 'auto' },
  open: { width: 'auto' },
};

const navVariants: Variants = {
  closed: { translateX: '-55%', width: '14rem' },
  open: { translateX: '0%', width: '14rem' },
  small: { translateX: '0%', width: 'auto' },
};

const SideNavContext = createContext<{ state: 'open' | 'closed' | 'small' }>({
  state: 'open',
});

export const SideNav: React.FC<SideNavProps> = ({ children, className = '', open = true }) => {
  const [initialOpen, setInitialOpen] = useState(open);
  const state: 'open' | 'closed' | 'small' = open ? 'open' : 'closed';

  useEffect(() => {
    setInitialOpen(false);
  }, []);

  const duration = 0.25;

  return (
    <motion.div
      variants={wrapperVariants}
      initial={initialOpen ? 'open' : 'closed'}
      animate={state}
      transition={{ ease: 'easeOut', duration }}
      className={`overflow-hidden bg-white border-r border-r-gray-300 ${className}`}
    >
      <motion.nav
        variants={navVariants}
        initial={initialOpen ? 'open' : 'closed'}
        animate={state}
        transition={{ ease: 'easeOut', duration }}
        className={`left-0 top-0 h-full pt-4 text-sm`}
      >
        <SideNavContext.Provider value={{ state }}>{children}</SideNavContext.Provider>
      </motion.nav>
    </motion.div>
  );
};

export type SideNavLinkProps = {
  children?: React.ReactNode;
  icon?: ReactElement;
  to: string;
};

export const SideNavLink: React.FC<SideNavLinkProps> = ({ children, icon, to }) => {
  const { state } = useContext(SideNavContext);
  return (
    <ActiveLink
      href={to}
      className={`
      ${state !== 'small' ? 'pl-5' : 'px-3'}
      transition-all
      min-h-[44px] text-gray-900 flex items-center whitespace-nowrap
      hover:bg-slate-300 hover:bg-opacity-20
      `}
      activeClassName="font-bold bg-slate-300 bg-opacity-20"
    >
      {icon && <span className={`w-6 transition-all ${state !== 'small' ? 'mr-3' : ''}`}>{icon}</span>}
      {state != 'small' && children}
    </ActiveLink>
  );
};

export const SideNavTitle: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { state } = useContext(SideNavContext);
  return (
    <div className="text-gray-900 pl-5 py-2 text-xs tracking-wide whitespace-nowrap">
      {state != 'small' && children }
      {/* invisible space for line height */}
      {' '.replace(/ /g, "\u00A0")}
    </div>
  );
};

export const SideNavSection: React.FC<{
  title?: ReactNode;
  children?: React.ReactNode;
}> = ({ title, children }) => (
  <div className="mb-3">
    {title && <SideNavTitle>{title}</SideNavTitle>}
    {children}
  </div>
);
