import { hashCode } from 'ui';

const chipValueBasedColors = [
  'bg-yellow-200 active:bg-yellow-300',
  'bg-blue-200 active:bg-blue-300',
  'bg-pink-200 active:bg-pink-300',
  'bg-indigo-200 active:bg-indigo-300',
  'bg-red-200 active:bg-red-300',
  'bg-sky-200 active:bg-sky-300',
  'bg-violet-200 active:bg-violet-300',
  'bg-orange-200 active:bg-orange-300',
  'bg-green-200 active:bg-green-300',
  'bg-slate-200 active:bg-slate-300',
  'bg-amber-200 active:bg-amber-300',
  'bg-lime-200 active:bg-lime-300',
  'bg-teal-200 active:bg-teal-300',
  'bg-fuchsia-200 active:bg-fuchsia-300',
  'bg-rose-200 active:bg-rose-300',
  '',
];

export const Chip: React.FC<{
  children?: string;
  className?: string;
  iconRight?: React.ReactChild;
  onIconRightClick?: () => void;
  valueBasedColor?: boolean;
}> = ({ children, className = '', valueBasedColor, iconRight, onIconRightClick }) => {
  // children's value based background color
  if (valueBasedColor && children) {
    className +=
      ' ' +
      chipValueBasedColors[Math.abs(hashCode(children)) % chipValueBasedColors.length];
  }

  // default colors
  if (!className) {
    className = 'text-gray-500 bg-gray-200 active:bg-gray-300';
  }

  return (
    <span
      className={`
        ${className} px-2 py-1 rounded-full font-semibold text-xs flex 
        align-center w-max transition duration-300 ease
      `}
    >
      {children}

      {iconRight && (
        <span
          className={`
            ml-2 w-4 h-4 rounded-full flex justify-center justify-self-center 
            hover:bg-black hover:bg-opacity-25
            ${onIconRightClick ? 'cursor-pointer' : ''}
          `}
          onClick={onIconRightClick}
        >
          {iconRight}
        </span>
      )}
    </span>
  );
};
