import React, { useRef } from 'react';
import { AnimatePresence, motion, LayoutGroup } from 'framer-motion';
import { useDocumentClick } from './hooks';
import { TabsMenuProps } from './TabsMenu';
import { CrossIcon } from 'ui';

type MediumModalProps = {
  children?: React.ReactNode;
  show: boolean;
  closeOnBackdropClick?: boolean;
  onClose?: () => void;
  // smoothGrow enables the modal to grow smoothly when opened.
  // the largest height will be cached so the modal will never become smaller than the largest height.
  smoothGrow?: boolean;
};

export const MediumModal: React.FC<MediumModalProps> = ({
  show,
  children,
  smoothGrow = false,
  closeOnBackdropClick = false,

  onClose = () => {},
}) => {
  const modalContainerRef = useRef<HTMLDivElement>(null);
  useDocumentClick(() => {
    if (show && closeOnBackdropClick) {
      onClose();
    }
  }, modalContainerRef);

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ background: 'rgba(0,0,0,0)' }}
          animate={{ background: 'rgba(0,0,0,0.2)' }}
          exit={{ background: 'rgba(0,0,0,0)' }}
          className="fixed w-screen h-screen left-0 top-0 bg-transparent z-30 flex justify-center pt-24 px-6 pb-24 overflow-y-auto"
        >
          <motion.div
            ref={modalContainerRef}
            className="w-full max-w-7xl shadow-lg bg-red-300 self-start flex flex-col justify-start"
            initial={{ opacity: 0, translateY: '100px' }}
            animate={{ opacity: 1, translateY: 0 }}
            exit={{ opacity: 0, translateY: '-100px' }}
            transition={{ ease: 'easeInOut' }}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default MediumModal;

const spring = {
  type: 'spring',
  stiffness: 500,
  damping: 30,
};

export function ModalTabsMenu<T extends number | string>({
  items,
  onChange,
  value,
  onClose,
}: TabsMenuProps<T> & { onClose?: () => void }) {
  return (
    <nav
      className="flex w-full justify-evenly md:justify-start bg-white overflow-x-clip relative"
      title="tabs navigation"
    >
      <LayoutGroup>
        {items.map((item) => (
          <button
            className={`w-full md:w-36 font-medium py-2 text-center relative transition-colors border-b border-gray-100 z-index-20 ${
              item.value === value ? 'text-secondary' : 'text-slate-400 bg-white'
            }`}
            key={item.value}
            onClick={() => onChange?.(item.value)}
          >
            <span className="relative z-10 text-base">{item.label}</span>
            {item.value === value && (
              <motion.div
                layoutId="selected-tab"
                className="absolute w-full h-[calc(100%+1px)] bg-gray-50 top-0 left-0 border-b border-slate-50"
                transition={{ ...spring }}
              />
            )}
          </button>
        ))}
      </LayoutGroup>

      <div className="border-b border-gray-100 flex-1 flex">
        {onClose && (
          <button onClick={onClose} className="self-center ml-auto p-1 mr-2 group">
            <div className="group-hover:scale-110 group-active:scale-100 transition-all bg-secondary w-5 h-5 rounded flex justify-center items-center">
              <CrossIcon className="text-white w-full" />
            </div>
          </button>
        )}
      </div>
    </nav>
  );
}
