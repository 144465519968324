import { useEffect } from 'react';
import { useMax } from 'ui/hooks';
import { useElementSize } from './useElementSize';

// useMaxHeight keeps track of the height of a component over time and always returns
// the maximum height of the component, even if the component became smaller afterwards.
export const useMaxHeight = <T extends HTMLElement>(config?: {
  resetOnWidthChange?: boolean;
}): [setRef: (node: T | null) => void, height: number] => {
  const [setRef, size] = useElementSize<T>();
  const [maxHeight, reset] = useMax(size.height);
  useEffect(() => {
    if (config?.resetOnWidthChange) {
      reset(0);
    }
  }, [config?.resetOnWidthChange, reset, size.width]);
  return [setRef, maxHeight];
};
