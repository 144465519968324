import React, { useState } from 'react';
import { Range, getTrackBackground } from 'react-range';

type RangeSelectProps = {
  values: number[];
  onChange?: (values: number[]) => void;
  step?: number;
  min: number;
  max: number;
  renderLabel?: (value: number) => string;
  inputFields?: boolean;
};

export const RangeSelect: React.FC<RangeSelectProps> = ({
  inputFields,
  values: propValues,
  onChange: propOnChange,
  min,
  max,
  renderLabel,
  step = 0.1,
}) => {
  const [_values, _setValues] = useState<number[]>(propValues);

  const values = propOnChange ? propValues : _values;
  const onChange = propOnChange || _setValues;

  return (
    <div className="flex">
      {inputFields && (
        <input
          className="w-12 px-1"
          onFocus={(e) => e.target.select()}
          value={values[0]}
          onChange={(e) => onChange([Number(e.target.value), values[1]])}
        />
      )}
      <div className="w-full">
        <Range
          step={step}
          min={min}
          max={max}
          values={values}
          onChange={onChange}
          renderTrack={({ props, children }) => (
            <div className="mx-2">
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                }}
                className="
            h-9 w-full flex
            after:w-full after:h-2 after:-m-1 after:bg-slate-200 after:absolute 
            after:left-0 after:top-1/2 after:-z-10
            after:rounded-md"
              >
                <div
                  ref={props.ref}
                  className="h-4"
                  style={{
                    width: '100%',
                    borderRadius: '4px',
                    background: getTrackBackground({
                      values,
                      colors: ['transparent', 'rgb(33 53 102)', 'transparent'],
                      min,
                      max,
                    }),
                    alignSelf: 'center',
                  }}
                >
                  {children}
                </div>
              </div>
            </div>
          )}
          renderThumb={({ props, index }) => (
            <div
              {...props}
              style={{
                ...props.style,
              }}
              className="bg-white border-[3px] box-border flex justify-center border-secondary w-4 h-4 rounded-full"
            >
              {/* invisible larger thumb for easier grip */}
              <div className="absolute bg-transparent w-7 h-7 rounded-full self-center"></div>
              {renderLabel && (
                // label under thumb displaying current value
                <div className="absolute top-4 cursor-pointer whitespace-nowrap font-mono text-xs">
                  {renderLabel(values[index])}
                </div>
              )}
            </div>
          )}
        />
      </div>
      {inputFields && (
        <input
          className="w-12 pl-1"
          onFocus={(e) => e.target.select()}
          value={values[1]}
          onChange={(e) => onChange([values[0], Number(e.target.value)])}
        />
      )}
    </div>
  );
};

export default RangeSelect;
