import Image from 'next/image';
import React from 'react';

type mapBoxStaticImgSrcProps = {
  mapboxToken: string;
  longitude: number;
  latitude: number;
  zoom?: number;
  width?: number;
  height?: number;
  style?: string;
};

export function mapBoxStaticImgSrc({
  mapboxToken,
  longitude,
  latitude,
  zoom = 12.5,
  width = 200,
  height = 200,
  style = 'mapbox://styles/mapbox/streets-v11',
}: mapBoxStaticImgSrcProps) {
  const strippedStyle = style.replace('mapbox://styles/', '');
  return `https://api.mapbox.com/styles/v1/${strippedStyle}/static/${longitude},${latitude},${zoom},0/${width}x${height}@2x?access_token=${mapboxToken}`;
}

type StaticMapBoxProps = {
  className?: string;
  imgWidth: number;
  imgHeight: number;
  longitude: number;
  latitude: number;
  zoom?: number;
  mapboxToken: string;
};

export const StaticMapBox: React.FC<StaticMapBoxProps> = ({
  className = '',
  longitude,
  latitude,
  zoom,
  mapboxToken,
  imgWidth,
  imgHeight,
}) => {
  const src = mapBoxStaticImgSrc({
    mapboxToken,
    longitude,
    latitude,
    zoom,
  });
  return (
    <div className={`relative ${className}`}>
      <Image
        src={src}
        alt={`map of ${longitude},${latitude}`}
        width={imgWidth}
        height={imgHeight}
        layout="fixed"
      />
    </div>
  );
};

export default StaticMapBox;
