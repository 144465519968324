import { Triangle } from './icons/Triangle';

export type PaginationProps = {
  current: number;
  pages: number;
  onChange: (page: number) => void;
  infinite?: boolean;
};

const MINIMAL_PAGE_ITEM_COUNT = 7;
function generatePageItems(current: number, total: number, width: number) {
  if (width < MINIMAL_PAGE_ITEM_COUNT) {
    throw new Error(`Must allow at least ${MINIMAL_PAGE_ITEM_COUNT} page items`);
  }
  if (width % 2 === 0) {
    throw new Error(`Must allow odd number of page items`);
  }
  if (total < width) {
    return Array.from(new Array(total).keys());
  }
  const left = Math.max(0, Math.min(total - width, current - Math.floor(width / 2)));
  const items: (undefined | number)[] = new Array(width);
  for (let i = 0; i < width; i += 1) {
    items[i] = i + left;
  }
  // replace non-ending items with placeholders
  if (items[0] !== undefined && items[0] > 0) {
    items[0] = 0;
    items[1] = undefined;
  }
  const last = items[items.length - 1]
  if (last !== undefined && last < total - 1) {
    items[items.length - 1] = total - 1;
    items[items.length - 2] = undefined;
  }
  return items;
}

export function getPageNumbers(
  current: number,
  total: number,
  leftRightNeighbors: number,
  infinite = false,
) {
  const width = leftRightNeighbors * 2 + 5;
  const result = generatePageItems(current, total, width);
  if (infinite) {
    if (result.length < width) {
      return result.concat(undefined)
    }

    if (result[result.length - 1] !== undefined) {
      result[result.length - 1] = undefined;
      if (result[result.length - 2] === undefined) {
        return result.slice(0, result.length - 1);
      }
      return result;
    }
  }

  return result;
}

export const Pagination: React.FC<PaginationProps> = ({
  current,
  pages,
  onChange,
  infinite,
}) => {
  const onClick = (page: number) => {
    if (page === current) {
      return;
    }

    if (page < 0) {
      return onChange(0);
    }

    if (page > pages - 1) {
      return onChange(pages - 1);
    }

    onChange(page);
  };

  const pageNumbers = getPageNumbers(current, pages, 2, infinite);

  return (
    <ul className="inline-flex -space-x-px">
      <PaginationItem onClick={() => onClick(current - 1)}>
        <Triangle
          className={`-rotate-90 text-secondary ${
            current === 0 ? 'opacity-50' : ''
          }`}
        />
      </PaginationItem>
      {pageNumbers.map((page, i) => (
        <PaginationItem
          key={`${page}_${i}`}
          onClick={() => page !== undefined && onClick(page)}
          active={page === current}
        >
          {page !== undefined ? page + 1 : '...'}
        </PaginationItem>
      ))}

      <PaginationItem onClick={() => onClick(current + 1)}>
        <Triangle
          className={`rotate-90 text-secondary ${
            current === pages - 1 ? 'opacity-50' : ''
          }`}
        />
      </PaginationItem>
    </ul>
  );
};

export const PaginationItem: React.FC<{ children?: React.ReactNode; active?: boolean; onClick: () => void }> = ({
  active = false,
  children,
  onClick,
}) => (
  <li>
    <button
      onClick={onClick}
      className={`h-full
        ${
          active
            ? 'bg-secondary text-white'
            : 'bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-700'
        }
        py-2 px-1 min-w-[2.375rem] leading-tight border border-gray-300 flex justify-center items-center`}
    >
      {children}
    </button>
  </li>
);
