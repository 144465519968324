import { useEffect, RefObject } from 'react';
/**
 * a hook for React which calls a provided callback whenever the document is clicked,
 * optionally ignoring all click within a passed HTMLElement
 * @param callback will be called upon clicking outside of the ignored element
 * @param ignoreNode optional HTMLElement of which to ignore click events
 */
export function useDocumentClick(
  callback: () => void,
  ignoreNode?: RefObject<HTMLElement> | HTMLElement | null,
): void {
  useEffect(() => {
    const clickListener = (ev: MouseEvent) => {
      if (ignoreNode) {
        const node = 'current' in ignoreNode ? ignoreNode.current : ignoreNode;
        if (
          node &&
          ev.target &&
          (node === ev.target || node?.contains(ev.target as Node))
        ) {
          return;
        }
      }

      callback();
    };

    document.addEventListener('click', clickListener);
    return () => document.removeEventListener('click', clickListener);
  }, [callback, ignoreNode]);
}
