import { AxiosError } from 'axios';
import { useRouter } from 'next/navigation';
import { useState, useEffect } from 'react';

import ory from './ory';

// Returns a function which will log the user out
// dep1, dep2, dep3 are optional deps. When these change, the self-service logout url  will be reinitialized.
// the reason for using individual deps instead of a spread operator is to avoid triggering the `react-hooks/exhaustive-deps` warning
// when using a non statically checkable deps array in the useEffect hook.
export function useLogoutHandler(dep1?: unknown, dep2?: unknown, dep3?: unknown) {
  const [logoutToken, setLogoutToken] = useState<string>('');
  const router = useRouter();

  useEffect(() => {
    ory
      .createSelfServiceLogoutFlowUrlForBrowsers()
      .then(({ data }) => {
        setLogoutToken(data.logout_token);
      })
      .catch((err: AxiosError) => {
        switch (err.response?.status) {
          case 401:
            // do nothing, the user is not logged in
            return;
        }

        // Something else happened!
        return Promise.reject(err);
      });
  }, [dep1, dep2, dep3]);

  return () => {
    if (logoutToken) {
      ory
        .submitSelfServiceLogoutFlow(logoutToken)
        .then(() => router.push('/login'))
        .then(() => router.refresh());
    }
  };
}
