export const HamburgerLogo: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 54 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect opacity="0.12" width="54" height="37" rx="3" fill="gray" />
    <path
      d="M17.7143 12.4453H36.2857C36.6802 12.4453 37 12.0956 37 11.6641V9.71094C37 9.27944 36.6802 8.92969 36.2857 8.92969H17.7143C17.3198 8.92969 17 9.27944 17 9.71094V11.6641C17 12.0956 17.3198 12.4453 17.7143 12.4453ZM17.7143 20.2578H36.2857C36.6802 20.2578 37 19.9081 37 19.4766V17.5234C37 17.0919 36.6802 16.7422 36.2857 16.7422H17.7143C17.3198 16.7422 17 17.0919 17 17.5234V19.4766C17 19.9081 17.3198 20.2578 17.7143 20.2578ZM17.7143 28.0703H36.2857C36.6802 28.0703 37 27.7206 37 27.2891V25.3359C37 24.9044 36.6802 24.5547 36.2857 24.5547H17.7143C17.3198 24.5547 17 24.9044 17 25.3359V27.2891C17 27.7206 17.3198 28.0703 17.7143 28.0703Z"
      fill="currentColor"
    />
  </svg>
);
