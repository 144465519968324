import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';

export const DataTableValue: React.FC<{
  children?: React.ReactNode;
  onLinkClick?: (url: string) => void;
}> = ({ children, onLinkClick }) => {
  if (typeof children !== 'string' || !children) {
    return <>{children}</>;
  }

  if (children === '[]()') {
    return null;
  }

  const link = /\[(.*)\]\((.*)\)/.exec(children);

  if (link && link.length >= 3) {
    let label = link[1];
    const href = link[2];

    if (['maps', 'link'].includes(label)) {
      label = '';
    }

    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={`flex ${label ? '' : 'justify-center'}`}
        onClick={onLinkClick ? () => onLinkClick(href) : undefined}
        onAuxClick={onLinkClick ? () => onLinkClick(href) : undefined}
      >
        {label && <span className="mr-3">{label}</span>}
        <ArrowTopRightOnSquareIcon className="w-5" />
      </a>
    );
  }

  if (
    children.indexOf(' ') === -1 &&
    (children.startsWith('http://') || children.startsWith('https://'))
  ) {
    return (
      <a
        href={children}
        title="link to external site"
        target="_blank"
        rel="noopener noreferrer"
        className="flex justify-center"
        onClick={onLinkClick ? () => onLinkClick(children) : undefined}
        onAuxClick={onLinkClick ? () => onLinkClick(children) : undefined}
      >
        <ArrowTopRightOnSquareIcon className="w-5" />
      </a>
    );
  }

  return <>{children}</>;
};
