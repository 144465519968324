import Link from 'next/link';
import { ReactNode } from 'react';
import { HamburgerLogo } from './logos';

export type HeaderProps = {
  children?: ReactNode;
  className?: string;
  logo?: ReactNode;
  onToggleMenu?: () => void;
  end?: ReactNode
};

export const Header: React.FC<HeaderProps> = ({
  className,
  logo,
  children,
  onToggleMenu,
  end,
}) => {
  return (
    <header
      className={`bg-secondary py-5 px-4 flex flex-row items-center gap-6 text-white h-20  ${className}`}
    >
      <button
        onClick={onToggleMenu}
        className="active:opacity-75 hover:scale-105 active:scale-95 transition-all"
      >
        <HamburgerLogo className="w-12 cursor-pointer" />
      </button>
      <Link href='/'><div className='cursor-pointer'>{logo}</div></Link>
      <div className="font-medium text-xl">{children}</div>
      <div className="flex flex-1 justify-end">
        {end}
      </div>
    </header>
  );
};
