import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { useElementRefSize } from './hooks';

type AnimatedHeightProps = {
  children?: React.ReactNode;
  min?: number;
};

export const AnimatedHeight: React.FC<AnimatedHeightProps> = ({ children, min }) => {
  const ref = useRef<HTMLDivElement>(null);
  const size = useElementRefSize(ref);
  const height = Math.max(size.height, min || 0);
  return (
    <motion.div animate={{ height }} transition={{}} className="relative">
      <div className="absolute w-full left-0 top-0 right-0 bottom-0 overflow-y-hidden">
        <div ref={ref}>{children}</div>
      </div>
    </motion.div>
  );
};

export default AnimatedHeight;

export const MaxHeightContainer: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      const currentHeight = containerRef.current.offsetHeight;

      if (currentHeight > maxHeight) {
        setMaxHeight(currentHeight);
      }
    }
  }, [children, maxHeight]); // Re-run effect when children change

  return (
    <div ref={containerRef} style={{ minHeight: `${maxHeight}px` }}>
      {children}
    </div>
  );
};
